import React, { Component } from 'react';
import { Button, ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react-v3';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { UserInputModal } from '../../common/UserInputModal';
import {
    SubmitBusinessRecommendationModalAttributes,
    SubmitCustomerDecisionModalAttributes,
    IButtonHandler,
} from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { Realm } from '@amzn/limestone-experiment-portal-types';
import { IProps, LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { ActionType, PermissionsMap, UserAccessLevel } from '@amzn/limestone-experiment-portal-types';
import RMSApiHandler from '../../api/region-service/handler/rms-api-handler';
import { CustomerDecision } from '../../form/attributes/CustomerDecision';
import { DisplayMode } from '@amzn/limestone-experiment-portal-types';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import { isActionAuthorizedForUser } from '../../utils/auth-utils';
import { BusinessRecommendation } from '../../form/attributes/BusinessRecommendation';

/**
 * Experiment statuses in which adding business recommendations are allowed.
 */
const EXPERIMENT_STATUS_ALLOWED_ADDING_BUSINESS_RECOMMENDATIONS = [
    ExperimentStatusType.ANALYSIS_IN_PROGRESS.toString(),
    ExperimentStatusType.AWAITING_CUSTOMER_DECISION.toString()
];

export interface ExperimentDecisionSectionProps extends IProps {
    realm: Realm;
    experimentId: string;
    setNotification: Function;
    userAccessLevels: Set<UserAccessLevel>;
    pagePermissionsMap: PermissionsMap;
    experiment: LimestoneExperiment;
    isAdmin: boolean;
}

export interface ExperimentDecisionSectionState {
    submitCustomerDecisionButtonLoading: boolean;
    showSubmitCustomerDecisionModal: boolean;
    submitBusinessRecommendationButtonLoading: boolean;
    showSubmitBusinessRecommendationModal: boolean;
    rablEmbedUrl: string;
    customerDecision: string;
    businessRecommendation: string;
}

/**
 * Section for customer decision and business recommendation in the experiment detail page.
 */
export class ExperimentDecisionSection extends Component<ExperimentDecisionSectionProps, ExperimentDecisionSectionState> {
    private readonly buttonHandlers: any;
    private readonly submitCustomerDecisionModalHandler: IButtonHandler;
    private readonly submitBusinessRecommendationModalHandler: IButtonHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    public regionManagementServiceAPI: RMSApiHandler;

    public constructor(props: any) {
        super(props);

        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
        this.regionManagementServiceAPI = new RMSApiHandler(props.realm);

        this.state = {
            submitCustomerDecisionButtonLoading: false,
            showSubmitCustomerDecisionModal: false,
            submitBusinessRecommendationButtonLoading: false,
            showSubmitBusinessRecommendationModal: false,
            rablEmbedUrl: '',
            customerDecision: this.props.experiment.customerDecision!,
            businessRecommendation: this.props.experiment.businessRecommendation!,
        };

        this.buttonHandlers = {
            submitCustomerDecision: () => this.setState({ showSubmitCustomerDecisionModal: true }),
            submitBusinessRecommendation: () => this.setState({ showSubmitBusinessRecommendationModal: true }),
        };

        this.submitCustomerDecisionModalHandler = {
            dismiss: () => this.setState({ showSubmitCustomerDecisionModal: false }),
            submit: () => this.submitCustomerDecision(),
        };

        this.submitBusinessRecommendationModalHandler = {
            dismiss: () => this.setState({ showSubmitBusinessRecommendationModal: false }),
            submit: () => this.submitBusinessRecommendation(),
        };
    }

    submitBusinessRecommendation = async() => {
        this.setState({ submitBusinessRecommendationButtonLoading: true, showSubmitBusinessRecommendationModal: false });

        await this.experimentServiceAPI.updateBusinessRecommendation(this.props.experimentId, this.state.businessRecommendation)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitBusinessRecommendation.SUCCESS);
                window.location.reload();
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitBusinessRecommendation.FAIL!))
            .finally(() => this.setState({ submitBusinessRecommendationButtonLoading: false }));
    }

    submitCustomerDecision = async() => {
        this.setState({ submitCustomerDecisionButtonLoading: true, showSubmitCustomerDecisionModal: false });

        await this.experimentServiceAPI.updateCustomerDecision(this.props.experimentId, this.state.customerDecision)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitCustomerDecision.SUCCESS);
                window.location.reload();
            })

            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitCustomerDecision.FAIL!))
            .finally(() => this.setState({ submitCustomerDecisionButtonLoading: false }));
    }

    updateCustomerDecision = (_fieldId: string, payloadValue: any, _displayValue: string, _isValid: boolean): void => {
        this.setState({
            customerDecision: payloadValue,
        });
    }

    updateBusinessRecommendation = (_fieldId: string, payloadValue: any, _displayValue: string, _isValid: boolean): void => {
        this.setState({
            businessRecommendation: payloadValue,
        });
    }

    render() {
        const isWriteActionEnabledForUser = isActionAuthorizedForUser(this.props.userAccessLevels, ActionType.WRITE, this.props.pagePermissionsMap);

        const modals = (
            <>
                <UserInputModal
                    visible={this.state.showSubmitBusinessRecommendationModal}
                    buttonHandlers={this.submitBusinessRecommendationModalHandler}
                    {...SubmitBusinessRecommendationModalAttributes}
                />
                <UserInputModal
                    visible={this.state.showSubmitCustomerDecisionModal}
                    buttonHandlers={this.submitCustomerDecisionModalHandler}
                    {...SubmitCustomerDecisionModalAttributes}
                />
            </>
        );

        const addingCustomerDecision: boolean = this.props.experiment.currentStatus.currentStatus.payloadValue === ExperimentStatusType.AWAITING_CUSTOMER_DECISION;
        const addingBusinessRecommendation: boolean = EXPERIMENT_STATUS_ALLOWED_ADDING_BUSINESS_RECOMMENDATIONS.includes(this.props.experiment.currentStatus.currentStatus.payloadValue)
            && !this.props.experiment.businessRecommendation;

        const content = (<>
            <ExpandableSection headerText={'Experiment Decision'} variant="container">
                <SpaceBetween direction="vertical" size="m">
                    <SpaceBetween direction="vertical" size="s">
                        <BusinessRecommendation
                            data-testid="business-recommendation-input"
                            displayMode={addingBusinessRecommendation && this.props.isAdmin ? DisplayMode.CREATE : DisplayMode.VIEW}
                            experimentId={this.props.experimentId}
                            realm={this.props.realm}
                            initialValue={this.props.experiment.businessRecommendation}
                            updateFormState={this.updateBusinessRecommendation}
                            isAuthorizedToEdit={isWriteActionEnabledForUser}
                        />
                        {addingBusinessRecommendation &&
                            <Button
                                data-testid="update-business-recommendation-button"
                                variant="primary"
                                onClick={this.buttonHandlers.submitBusinessRecommendation}
                            >Submit
                            </Button>
                        }
                    </SpaceBetween>
                    <SpaceBetween direction="vertical" size="s">
                        <CustomerDecision
                            data-testid="customer-decision-input"
                            displayMode={addingCustomerDecision ? DisplayMode.CREATE : DisplayMode.VIEW}
                            experimentId={this.props.experimentId}
                            realm={this.props.realm}
                            initialValue={this.props.experiment.customerDecision}
                            updateFormState={this.updateCustomerDecision}
                            isAuthorizedToEdit={isWriteActionEnabledForUser}
                        />
                        {addingCustomerDecision &&
                            <Button
                                data-testid="update-customer-decision-button"
                                variant="primary"
                                onClick={this.buttonHandlers.submitCustomerDecision}
                            >Submit
                            </Button>
                        }
                    </SpaceBetween>
                </SpaceBetween>
            </ExpandableSection>

            {modals}
        </>);
        return (
            <div style={{ paddingBottom: 20 }}>
                {content}
            </div>
        );
    }
}
